import { IVRType } from '../../../@types/ivr';

type GetIvrTestUrlProps = {
  ivrTypes: IVRType[];
  ivrName?: string;
  ivrId?: number;
};

function getIvrTestUrl({ ivrTypes, ivrName, ivrId }: GetIvrTestUrlProps): string {
  const key = ivrName ? 'name' : 'id';
  const value = ivrName || ivrId;

  const ivrType = ivrTypes.find((type) => type[key] === value);
  return ivrType?.properties?.test?.url || '';
}

export { getIvrTestUrl };
