import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Dispatch, SetStateAction } from 'react';
import useCustomDispatch from 'redux/dispatch';
import {
  CustomDataGrid,
  CustomGridToolbar,
  CustomLoadingOverlay,
  CustomPagination
} from '../../../components/datagrid/Custom';
import { useMemo } from 'react';
import CustomButton from 'components/util/CustomButton';
import { DataGridKey } from 'redux/slices/datagrid';
import { getIvrMapToTest } from 'redux/slices/ivr';
import { RootState, useSelector } from 'redux/store';
import { getIvrTestUrl } from '../util/getIvrTestUrl';
import { IvrMap } from '../../../@types/ivr';

interface IVRListProps {
  ivrData: IvrMap[];
  loading: boolean;
  selectionModel: any;
  setSelectionModel: any;
  setIvrIdToEdit: Dispatch<SetStateAction<number | null>>;
}

export default function IVRList(props: IVRListProps) {
  const { ivrTypes } = useSelector((state: RootState) => state.ivr);
  const customDispatch = useCustomDispatch();
  const DATA_GRID_KEY = DataGridKey.IVRList;

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id'
      },
      {
        field: 'name',
        hide: false,
        headerName: 'Name',
        flex: 1
      },
      {
        field: 'providerName',
        hide: false,
        headerName: 'IVR',
        valueGetter: (params) => params.row.provider.name,
        flex: 1
      },
      {
        field: 'queueId',
        hide: false,
        headerName: 'Queue id',
        flex: 1
      },
      {
        field: 'campaignId',

        headerName: 'Campaign id',
        flex: 1
      },
      {
        field: 'campaignName',
        hide: false,
        headerName: 'Campaign Name',
        valueGetter: (params) => params.row.campaign.name,
        flex: 1
      },
      {
        field: 'test',
        headerName: 'Test',
        renderHeader: () => '',
        width: 60,
        hideable: false,
        editable: false,
        align: 'center',
        hideSortIcons: true,
        disableColumnMenu: true,
        filterable: false,
        disableExport: true,
        renderCell: (cellValues) => {
          const ivrName = cellValues.row.provider.name;
          const isIvrTestEnabled = Boolean(getIvrTestUrl({ ivrName, ivrTypes }));

          return (
            <>
              {isIvrTestEnabled && (
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <CustomButton
                    dataTestId="ivr-list-test"
                    onClick={() => {
                      customDispatch({
                        action: getIvrMapToTest,
                        actionParameters: cellValues.row.id,
                        disableSuccessMessage: true
                      });
                    }}
                  >
                    Test
                  </CustomButton>
                </div>
              )}
            </>
          );
        }
      }
    ],
    [customDispatch, ivrTypes]
  );

  return (
    <Box>
      <CustomDataGrid
        dataGridKey={DATA_GRID_KEY}
        onRowClick={(row) => props.setIvrIdToEdit(row.id as number)}
        autoHeight
        loading={props.loading}
        checkboxSelection
        disableSelectionOnClick
        rows={props.ivrData}
        columns={columns}
        pagination
        onSelectionModelChange={(newSelectionModel) => {
          props.setSelectionModel(newSelectionModel);
        }}
        selectionModel={props.selectionModel}
        components={{
          Toolbar: CustomGridToolbar,
          Pagination: CustomPagination,
          LoadingOverlay: CustomLoadingOverlay
        }}
      />
    </Box>
  );
}
